<template>

  <b-card>
    <div slot="header">
      <strong>Listar Clientes de Áreas</strong>
    </div>
    
    <div>
      <router-link :to="{ name: 'Criar Clientes de Áreas', params: { id: this.$route.params.id } }" class="btn btn-primary">Gerenciar</router-link>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>Cliente</th>
          <th>Empresa</th>
          
        </tr>
      </thead>
      <tbody>
        <tr v-for="usuarioGrupoItem in usuariosGrupos" :key="usuarioGrupoItem.id">
          <td> {{ usuarioGrupoItem.usuario.name }}<br><small>{{ usuarioGrupoItem.email.name }}</small> </td>
          <td> {{ usuarioGrupoItem.grupo.mnemonico }} </td>
          
        </tr>
      </tbody>
    </table>

  </b-card>
  
</template>

<script>
export default {
  data () {
    return {
      usuariosGrupos: []
    }
  },
  created: function () {
    this.verificaSessao()
    this.usuariosGrupos = []
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.regraListar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    regraListar: function () {
      this.listar('filtro_User_Grupo', 'name', '(gtipo_ID = 2)').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.usuariosGrupos.push({
              grupo: { mnemonico: response[i].grp_mnemonico },
              usuario: { name: response[i].name },
              email: { name: response[i].email },
              id: response[i].ugrp_ID
            })
          }
        }
      )
    }
  }
}
</script>

<style>

</style>
